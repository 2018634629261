{
  "rtp":{
    "noticeNumber": "Notice code",
    "amount": "Amount",
    "description": "Description",
    "payeeCompanyName" : "Payee Company Name:",
    "payee": "Payee fiscal code",
    "payerId": "Payer fiscal code",
    "expiryDate": "Date",
    "pageDescription": "Enter the data as show on the payment notice.",
    "pageTitle": "Create a RTP (Request To Pay)"
  },
  "paymentNoticePage": {
    "formButtons": {
      "cancel": "Back",
      "submit": "Continue"
    },
    "formErrors": {
      "minCf": "Enter 11 or 16 numbers",
      "minCode": "Enter 18 numbers",
      "required": "Required field",
      "number": "Enter a valid number. To separate decimals use the dot (.)"
    },
    "formFields": {
      "billCode": "Notice code",
      "cf": "Payee tax code"
    },
    "helpLink": "View an example"
  },
  "__variant-name": "Inglese",
  "__variant-description": ""
}