"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tag = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const system_1 = require("@mui/system");
const styles_1 = require("@mui/material/styles");
const _theme_1 = require("../../theme/index");
/* Transform HTML component into MUI Styled Component
in order to accept `sx` prop */
const StyledTag = (0, system_1.styled)("span")({
    display: "inline-block",
    fontSize: (0, _theme_1.pxToRem)(14),
    fontWeight: 600,
    letterSpacing: 0.5,
    whiteSpace: "nowrap",
});
const Tag = (_a) => {
    var { value, color = "default", variant = "default", sx = {} } = _a, rest = tslib_1.__rest(_a, ["value", "color", "variant", "sx"]);
    const tagNeutralBg = _theme_1.theme.palette.grey[100];
    const tagBgColor = color !== "default"
        ? variant === "light"
            ? (0, styles_1.alpha)(_theme_1.theme.palette[color][100], 0.1)
            : _theme_1.theme.palette[color][100]
        : tagNeutralBg;
    const tagTextColor = color === "default" || color === "primary"
        ? _theme_1.theme.palette.text.primary
        : _theme_1.theme.palette[color][850];
    const style = Object.assign({ userSelect: "none", py: 0.5, px: 0.75, backgroundColor: tagBgColor, color: tagTextColor, fontFamily: _theme_1.theme.typography.fontFamily, borderRadius: _theme_1.theme.spacing(0.5) }, sx);
    return ((0, jsx_runtime_1.jsx)(StyledTag, Object.assign({ sx: style }, rest, { children: value })));
};
exports.Tag = Tag;
