{
  "rtp":{
    "noticeNumber": "Codice Avviso",
    "amount": "Importo",
    "description": "Descrizione",
    "payeeCompanyName" : "Nome Ente Creditore",
    "payee": "Codice Fiscale Ente Creditore",
    "payerId": "Codice Fiscale Debitore",
    "expiryDate": "Data",
    "pageDescription": "Inserisci i dati come mostrato sull'avviso di pagamento.",
    "pageTitle": "Create a RTP (Request To Pay)"
  },
  "paymentNoticePage": {
    "formButtons": {
      "cancel": "Indietro",
      "submit": "Continua"
    },
    "formErrors": {
      "minCf": "Inserisci 11 o 16 cifre",
      "minCode": "Inserisci 18 cifre",
      "required": "Campo obbligatorio",
      "number": "Inserisci un numero valido. Per separare i decimali utilizza il punto (.)"
      
    },
    "formFields": {
      "billCode": "Codice Avviso",
      "cf": "Codice Fiscale Ente Creditore"
    },
    "helpLink": "Vedi un esempio"
  }
}